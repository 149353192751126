.multi-drop-container {
  border: 2px solid #D6D6D6; /* Set border to 1px solid transparent */
  border-radius: 15px;
  margin-top: 1%;
  text-align: left;
  font-size: 14px;
}

.multi-drop-container:hover {
  border: 2px solid #000; /* Set border to 1px solid transparent */
  cursor: pointer; /* Optionally, change the cursor on hover */
}

.multi-drop-container:focus-within,
.multi-drop-container:focus-visible {
  outline: none !important;
  border: 2px solid var(--primary-clr);
}

.multi-drop-container .dropdown-content {
  border-top: none; /* Remove top border to prevent double borders */
}

/* Remove the default border added by MultiSelect */
.multi-drop-container .dark {
  border: none !important;
  border-radius: 15px !important;
}

.rmsc.dark {
  --rmsc-bg: #ffffff;
}

.dropdown-container{
  border-radius: 15px !important;
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important;
}

.multi-drop-container .dropdown-wrapper .item-checkbox {
  display: none;
}

.rmsc {
  --rmsc-border: none;
}

.rmsc .dropdown-container {
  border: none !important;
}

/* styles.css */
.dash-nav-container {
  border-bottom: none;
  width: 100%;
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  top: 0;
}

.nav-elements-dash {
  padding: 0px;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.nav-elements-dash ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.nav-elements-dash ul li:not(:last-child) {
  margin-right: 30px;
}

.nav-elements-dash ul a {
  color: #202020;
  text-decoration: none;
}

.nav-elements-dash ul a.active {
  color: #202020;
  font-weight: 500;
}

.nav-elements-dash ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
}

@media (max-width: 768px) {
  .nav-elements-dash ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 991px) {
  .menu {
    display: block;
  }

  .nav-elements-dash {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #ffffff;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements-dash.active {
    width: 270px;
  }

  .nav-elements-dash ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements-dash ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,500;9..40,600;9..40,700&display=swap");

.tes_container {
  width: 90%;
  margin: auto;
}

.Testimonial {
  padding: 20px 25px;
}

.Testimonial_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Testimonial_heading h2 {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
}

.Testimonial_content p {
  font-family: "DM Sans";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.main_heading p {
  font-family: "DM Sans";
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #c9f6ef;
}

.main_heading h2 {
  font-size: 48px;
  font-weight: 700;

  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

.find_mentor_btn {
  background-color: #c9f6ef;
  width: 186px;
  height: 58px;
  top: 183px;
  left: 1541px;
  padding: 12px, 30px, 12px, 30px;
  border: none;
  border-radius: 15px;
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}

.Testimonial_bg {
  background-color: #fff;
  border-radius: 5px;
  margin: 0 auto;
}

@media (max-width: 1300px) {
  .main_heading h2 {
    font-size: 40px;
  }
  .Testimonial_heading h2 {
    font-size: 27px;
  }
  .Testimonial_content p {
    font-size: 18px;
  }
}

@media (max-width: 640px) {
  .testimonials-swiper .swiper-slide {
    width: 95% !important;
  }
}

@media (max-width: 428px) {
  .Testimonial_top {
    flex-wrap: wrap;
  }

  .testimonials-swiper .swiper-slide {
    width: 92% !important;
    margin-right: 197px !important;
  }

  .Testimonial {
    padding: 10px 20px;
  }
  
  .find_mentor_btn {
    display: none;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,500;9..40,600;9..40,700&display=swap");

.navContainer {
  /* margin: 0 auto; */
  padding: 0px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0;
}

.navbar {
  height: 60px;
  background-color: #d1d2f4;
  position: relative;
  z-index: 1000;
  margin: auto;
}

.nav-container {
  border-bottom: none;
  width: 100%;

  background-color: #d1d2f4;
  position: fixed;
  z-index: 9999;
  top: 0;

  @media (max-width: 900px) {
    background-color: #fff;
  }
}

.menu-item {
  cursor: pointer;
  font-family: "Gilroy";
}

.logo {
  width: 200px;
}

.universities-d {
  display: none;
}

@media (max-width: 768px) {
  .navbar {
    width: 100%;
    margin: auto;
  }

  .logo {
    margin-bottom: 0px;
  }

  .universities-d {
    display: block;
    margin-top: 40px;
  }

  .universities-n {
    display: none !important;
  }
}

.con-po {
  width: 80%;
}

.menu-icon {
  cursor: pointer;
  width: 35px;
  position: relative;
  user-select: none;
  display: none;
  right: 0px;
}

.menu-icon .bar {
  display: inline-block;
  background: var(--primary-clr);
  border-radius:10px;
  height:3px;
  transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.bar:nth-of-type(1){
  width:50%;
}

.bar:nth-of-type(2){
  width:100%;
}

.bar:nth-of-type(3){
  width:75%;
}

.menu-icon.open .bar:nth-of-type(1) {
  transform-origin: bottom !important;
  transform: rotate(45deg) translate(5px, 0px) !important;
}

.menu-icon.open .bar:nth-of-type(2) {
  transform-origin: top !important;
  transform: rotate(-45deg) !important;
}

.menu-icon.open .bar:nth-of-type(3) {
  transform-origin: bottom !important;
  width: 50% !important;
  transform: translate(16px, -6px) rotate(45deg) !important;
}

.nav-elements {
  padding: 0px;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex: 1;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 0;
}

.header-dropdown-content {
  background-color: #ffffff;
  position: absolute;
  z-index: 1;
  min-width: 160px;
  max-height: calc(100vh - 125px);
  padding: 0;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
}

.header-dropdown-content li {
  line-height: 3rem;
  padding-inline: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-inline: 4px;
  margin-block: 4px;
  width: calc(100% - 8px);
}

.header-dropdown-content li {
  margin: 18px 12px !important;
}

.header-dropdown-content .nav-link-item {
  columns: var(--main-text-clr);
}

.header-dropdown-content .nav-link-item:hover,
.header-dropdown-content .nav-link-item:focus,
.header-dropdown-content .nav-link-item:active {
  cursor: pointer;
  background-color: var(--primary-clr-hover);
  font-weight: 700;
  border-radius: 8px;
  color: #fff;
}

.header-dropdown-content a {
  color: inherit;
  text-decoration: none;
  margin-inline-start: 16px;
  opacity: 1;
  margin-right: 50px;
  font-size: 16px;
  line-height: 18px;
}

@media (max-width: 1220px) {
  .nav-elements ul {
    gap: 50px;
  }
}

.nav-elements ul li:not(:last-child) {
  margin-right: 30px;
}

.multi-drop-back {
  background-color: #ffffff;
}

.nav-elements ul a.active {
  font-weight: 700;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
}

.menu {
  display: flex;
  flex: 1;
  justify-content: center;
}

ul.menu {
  margin-bottom: 0 !important;
}

.menu li {
  font-size: 18px;
  font-family: "DM Sans", sans-serif;
}

.submenu {
  transition: 0.3s;
  margin-left: 10px;
  margin-top: 6px;
}

@media (min-width: 991px) {
  .submenu {
    display: none;
  }
}

@media (max-width: 991px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 0px;
  }

  .header-dropdown-content {
    visibility: hidden;
  }

  .menu-icon {
    display: flex;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;
  }

  .menu {
    display: block;
  }

  .nav-elements {
    position: fixed;
    left: 0;
    top: 85px;
    margin-top: 12px;
    border-radius: 12px !important;
    width: -webkit-fill-available;
    right: 2.5%;
    left: 2.5%;
    width: 95%;
    display: none;
    background-color: #fff !important;
    box-shadow: 0 2px 10px rgba(15,16,16,.3) !important;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .nav-elements.active {
    display: block;
    opacity: 1;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    padding: 1rem 1.3rem;
  }

  .nav-elements ul div {
    width: 100%;
  }

  .nav-elements ul .menu li {
    padding-inline: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-inline: 4px;
    margin-block: 4px;
    line-height: 50px;
    align-items: center;
    transition: border-color 0.3s,background 0.3s,padding 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .nav-elements ul .menu .menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menu-item:hover {
    cursor: pointer;
    background-color: var(--primary-clr-hover);
    font-weight: 700;
    border-radius: 8px;
    color: #fff;
    padding: 0 10px;
  }

  .nav-elements ul .menu li .submenu {
    padding-inline: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-inline: 4px;
    margin-block: 4px;
    align-items: center;
    transition: border-color 0.3s,background 0.3s,padding 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .nav-elements ul .menu li .submenu a {
    display: block;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    color: var(--main-text-clr);
    text-decoration: none !important;
    cursor: pointer;
  }  
}

.vertical-devider {
  padding: 1px;
  background-color: var(--border-clr);
}
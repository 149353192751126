.wrapper {
  position: relative;
}

.has-search .form-control {
  padding-left: 1.575rem;
  width: 100%;
  align-self: center;
}
.form-group input:focus {
  outline: none;
  box-shadow: none;
}

h1 {
  font-family: "Gilroy";
}

h2 {
  font-family: "Gilroy";
}

@font-face {
  font-family: "Gilroy";
  src: url("./font/Gilroy-SemiBold.ttf") format("woff2");
  /* Add other font styles (font-weight, font-style) if applicable */
}
@font-face {
  font-family: "Gilroy";
  src: url("./font/Gilroy-Regular.ttf") format("woff2");
  /* Add other font styles (font-weight, font-style) if applicable */
}

@media (max-width: 750px) {
  .has-search .form-control {
    padding-left: 2.575rem;
    width: 19.375rem;
  }
}
/* @media screen and (min-width: 1280px) and (min-height: 800px) {
        .has-search .form-control {
            padding-left: 1.575rem;
            width: 23.175rem;
            align-self: center;
           
        }    }
    */

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  border-width: 0px;
}

.searchBar {
  display: flex;
  background-color: white;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 6px 3px 6px 16px;
  width: 70%;
  border-radius: 15px;
  border: 1px solid #ffe9d4;
}

/* How It Works  */

.HowItWorks {
  display: flex;
  gap: 40px;
}

.ViewAll_btn {
  background-color: #c9f6ef;
  width: 186px;
  height: 58px;
  top: 183px;
  left: 1541px;
  padding: 16px, 36px, 16px, 36px;
  border: none;
  border-radius: 15px;
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}

.howTtImg, .howTtContent{
  display: flex;
  justify-content: center;
}

@media (max-width: 1796px) {
  .Woman-image {
    width: 100% !important;
    padding:62px 0px 0px;
  }
}

@media (max-width: 1480px) {
  .Woman-image {
    width: 100% !important;
  }
  .subHeading {
    width: 600px !important;
  }
}

@media (max-width: 1024px) {
  .HowItWorks {
    flex-wrap: wrap !important;
  }
  .howTtImg {
    width: 100% !important;
  }
  .howTtImg img {
    width: 100% !important;
  }
  .howTtContent {
    width: 100% !important;
    padding-top: 40px;
  }
  .howItSubHeading {
    width: 100% !important;
  }
}

@media (max-width: 640px) {
  h2 {
    font-size: 30px !important;
    font-weight: 600 !important;
  }
  .heading {
    line-height: 60px !important;
    font-size: 35px !important;
  }
  .subHeading {
    width: 400px !important;
  }
  .searchBar {
    width: 90%;
  }

  .howTtContent {
    padding: 0;
  }
}

@media (max-width: 428px) {
  h2 {
    font-size: 24px !important;
    font-weight: 600 !important;
  }
  .heading {
    width: 90% !important;
    line-height: 40px !important;
    font-size: 32px !important;
  }
  .subHeading {
    width: 95% !important;
  }
  .searchBar {
    width: 95%;
  }
  .BMentor_btn {
    margin-right: 0px !important;
    display: flex;
    justify-content: center;
  }
  .FMentor_btn {
    margin: auto;
    display: flex;
    justify-content: center;
  }
}

.active-members {
  text-align: center;
  display: flex;
  margin-top: 10px;
  gap: 15px;
  margin-left: 15px;
}

.active-members-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.active-members-list img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-left: -15px;
}

.bg-red {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #68b9fc;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -15px;
}

.active-members-text {
  font-family: "DM Sans";
  font-size: 20px;
  font-weight: 400;
  color: #333;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .active-members-list img {
    width: 32px;
    height: 32px;
  }
  .active-members-text {
    font-size: 16px;
  }
}

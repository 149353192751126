.my-responsive-swiper .swiper-pagination {
  margin-top: 20px;
}

.swiper-slide {
  background-color: transparent;
  margin: 50px 0px 0px 0px;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 640px) {
  .swiper-slide {
    margin-top: 10px;
  }
}


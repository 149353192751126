.swiper-slide {
  /* text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center; */
}

.swiper-slide {
  /* width: 363px !important;
  height: 566px !important; */
  /* border-radius: 8px; */
}

.Mentor_content {
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
}

.Mentor_right {
  display: flex;
}

@media (max-width: 1450px) {
  .Mentor_content {
    width: 90%;
    margin: auto;
  }
  .Mentor_img img {
    width: 90%;
  }
}

.text-left {
  text-align: left !important;
}
.inputWithButton {
  position: relative;
  height: 100px;
  width : 200px;
}

.inputWithButton input{
    width: 70%;
    height: 25px;
    padding-right: 60px;
}

.inputbutton {
  position: absolute;
  right: 0;
  top : 5px;
}
@font-face {
  font-family: "Gilroy Bold";
  src: url("./Assets/font/Gilroy-SemiBold.ttf") format("woff2");
}

@font-face {
  font-family: "Gilroy Thin";
  src: url("./Assets/font/Gilroy-Thin.ttf") format("woff2");
}

@font-face {
  font-family: "Gilroy";
  src: url("./Assets/font/Gilroy-Regular.ttf") format("woff2");
}

:root {
  --main-text-clr: #202020;
  --border-clr: #D9D9D9;
  --primary-clr: #7476D1;
  --primary-clr-light: #6C6EBE;
  --primary-clr-shade-1: #A3A5E9;
  --light-teal-clr: #C9F6EF;

  --primary-clr-hover: rgba(116, 118, 209, 0.5);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
* {
  font-family:
    "Gilroy",
    -apple-system,
    BlinkMacSystemFont,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    Arial,
    sans-serif !important;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scroll-behavior: smooth;
}

body {
  background-color: transparent !important;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-semibold {
  font-weight: 700;
}

.m-0 {
  margin: 0 !important;
}

.text-disabled {
  color: var(--textColorSecondary) !important;
}

.h1, .h2, .h3, .h4, .h5, .h6,
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  margin-bottom: 0;
}

.back-transparent {
  background-color: transparent !important;
}

.no-padding {
  padding: 0px !important;
}

.cursor-hover:hover {
  cursor: pointer;
}

.ql-editor {
  min-height: 200px;
}

img {
  max-width: 100%;
}

/*-----------Scroll bar----------------- */
::-webkit-scrollbar {
  width: 1em;
}

::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}

::-webkit-scrollbar-thumb {
  background: rgb(185, 184, 184);
  border: 0.3em solid white;
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(146, 146, 146);
}
